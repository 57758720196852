module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_5cmwh325ac26rag4qauoca3try/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#7500ff","display":"minimal-ui","icon":"src/images/babyganoush-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4f4e32b99690808504a66b8b8ba87f8c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__4vyicdgg2gob2b5cajncmrwob4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
